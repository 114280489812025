/**
 * NOTE! This context is persistant on route change
 * Remember to import it in gatsby-browser.js
 */

import React, { useState } from 'react'
import { createContext } from 'react'

const GlobalContext = createContext({})

export const GlobalProvider = ({ children }) => {
  const [state, setState] = useState({
    testValue: 0
  })

  const setTestValue = () => {
    const newValue = state.testValue + 1
    setState(prevState => ({
      ...prevState,
      testValue: newValue
    }))
  }

  return (
    <GlobalContext.Provider
      value={{
        state: state,
        actions: {
          setTestValue
        }
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalContext
