exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-99-x-group-js": () => import("./../../../src/pages/99xGroup.js" /* webpackChunkName: "component---src-pages-99-x-group-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-awards-js": () => import("./../../../src/pages/awards.js" /* webpackChunkName: "component---src-pages-awards-js" */),
  "component---src-pages-boardofdirectors-js": () => import("./../../../src/pages/boardofdirectors.js" /* webpackChunkName: "component---src-pages-boardofdirectors-js" */),
  "component---src-pages-brazil-js": () => import("./../../../src/pages/brazil.js" /* webpackChunkName: "component---src-pages-brazil-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-centers-of-excellence-js": () => import("./../../../src/pages/centers-of-excellence.js" /* webpackChunkName: "component---src-pages-centers-of-excellence-js" */),
  "component---src-pages-component-library-js": () => import("./../../../src/pages/componentLibrary.js" /* webpackChunkName: "component---src-pages-component-library-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-malaysia-js": () => import("./../../../src/pages/malaysia.js" /* webpackChunkName: "component---src-pages-malaysia-js" */),
  "component---src-pages-method-js": () => import("./../../../src/pages/method.js" /* webpackChunkName: "component---src-pages-method-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-norway-js": () => import("./../../../src/pages/norway.js" /* webpackChunkName: "component---src-pages-norway-js" */),
  "component---src-pages-ourmanagement-js": () => import("./../../../src/pages/ourmanagement.js" /* webpackChunkName: "component---src-pages-ourmanagement-js" */),
  "component---src-pages-partner-referral-js": () => import("./../../../src/pages/partnerReferral.js" /* webpackChunkName: "component---src-pages-partner-referral-js" */),
  "component---src-pages-product-design-js": () => import("./../../../src/pages/productDesign.js" /* webpackChunkName: "component---src-pages-product-design-js" */),
  "component---src-pages-references-js": () => import("./../../../src/pages/references.js" /* webpackChunkName: "component---src-pages-references-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-sri-lanka-js": () => import("./../../../src/pages/sri-lanka.js" /* webpackChunkName: "component---src-pages-sri-lanka-js" */),
  "component---src-pages-styleguide-js": () => import("./../../../src/pages/styleguide.js" /* webpackChunkName: "component---src-pages-styleguide-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

