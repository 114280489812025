import ReactDOM from "react-dom/client";
import '@popperjs/core' // Edit here
import 'bootstrap/dist/css/bootstrap.min.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const React = require('react')
const { GlobalProvider } = require('./src/components/context/GlobalContext')

require('./src/styles/app.scss')

export const replaceHydrateFunction = () => { //added to fix the minified react error #423 and #418
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}

export const wrapRootElement = ({ element }) => (
  <GlobalProvider>{element}</GlobalProvider>
)

// IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
export const onClientEntry = async () => {
  if (typeof window.IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }
}
